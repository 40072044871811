import Color from "../interfaces/Color";

const colors = {
  primary: new Color("#000D71"),
  secondary: new Color("#667D4E"),
  black: new Color("#1D1C1A"),
  white: new Color("#FFFFFF"),
  grey1: new Color("#898989"),
  grey2: new Color("#E9E5E2"),
  grey3: new Color("#F4F4F1"),
  background: new Color("#FBFBFB"),
  discount: new Color("#AE4A1D"),
  information: new Color("#667D4E"),
  error: new Color("#DA3823"),
  highlight: new Color("#FFCB2E"),
  hover: new Color("#010B5B"),
  disabled: new Color("#CCCFE3"),
  informationBG: new Color("#F0F2ED"),
  errorBG: new Color("#DA3823", 0.05),
  highlightBG: new Color("#FFCB2E", 0.05),
};

export default {
  typography: {
    fontFamily: "Visuelt Pro",
    fontFamilyAlt: "Ivar Headline",
  },
  colors: {
    text: "#202020",
    pea: colors.primary,
    pea80: colors.primary,
    pea40: colors.primary,
    pea10: colors.primary,
    pea100: colors.grey2,
    basil: "#304523",
    basil80: "rgba(48, 69, 35, 0.8)",
    basil40: "rgba(48, 69, 35, 0.4)",
    basil10: "#EAECE9",
    basil100: "rgba(48, 69, 35, 0.05)",
    tumeric: "#AE4A1D",
    tumeric100: "rgba(174, 74, 29, 0.05)",
    bahama: "#025998",
    bahama100: "rgba(2, 89, 152, 0.05)",
    yolk: "#FFA502",
    yolk100: "rgb(255, 165, 2, 0.05)",
    darkGray: "#a9a9a9",
    gray: "#D8D8D8",
    gray80: "rgba(216, 216, 216, 0.8)",
    gray40: "rgba(216, 216, 216, 0.4)",
    gray10: "rgba(216, 216, 216, 0.1)",
    disabledBg: "#F4F6F6",
    disabledFg: "#e0e3de",
    placeholder: "#666666",
    success: "#304523",
    info: "#025998",
    warning: "#FFA502",
    primary: new Color("#000D71"),
    secondary: new Color("#667D4E"),
    black: new Color("#1D1C1A"),
    white: new Color("#FFFFFF"),
    grey1: new Color("#898989"),
    grey2: new Color("#E9E5E2"),
    grey3: new Color("#F4F4F1"),
    background: new Color("#FBFBFB"),
    discount: new Color("#AE4A1D"),
    information: new Color("#667D4E"),
    error: new Color("#DA3823"),
    highlight: new Color("#FFCB2E"),
    hover: new Color("#010B5B"),
    disabled: new Color("#CCCFE3"),
    informationBG: new Color("#F0F2ED"),
    errorBG: new Color("#DA3823", 0.05),
    highlightBG: new Color("#FFCB2E", 0.05),
  },
  fontSize: {
    h1: {
      desktop: "48px",
      mobile: "32px",
    },
    h2: {
      desktop: "32px",
      mobile: "24px",
    },
    h3: {
      desktop: "24px",
      mobile: "18px",
    },
    caption: "10px",
    small: "12px",
    regular: "14px",
    large: "16px",
    larger: "18px",
  },
  spacing: {
    0: "0px",
    1: "4px",
    2: "8px",
    3: "12px",
    4: "16px",
    5: "24px",
    6: "32px",
    7: "40px",
    8: "56px",
  },
  screen: {
    small: `(min-width: 320px)`,
    medium: `(min-width: 768px)`,
    large: `(min-width: 1024px)`,
    xLarge: `(min-width: 1440px)`,
    smallMax: `(max-width: 319px)`,
    mediumMax: `(max-width: 767px)`,
    largeMax: `(max-width: 1023px)`,
    xLargeMax: `(max-width: 1439px)`,
  },
  general: {
    borderRadius: "2px",
    maxContentWidth: "1440px",
    boxShadow:
      "0px 4px 12px rgba(53, 55, 58, 0.08), 0px 2px 2px rgba(0, 0, 0, 0.08)",
  },
};
