import React from "react";

import { Loader } from "../../primitives";

function LoaderContainer(props) {
  const { isFullPage = false, isWholeScreen = false } = props;

  return (
    <div key="loading" className="flex-grow">
      <Loader isFullPage={isFullPage} isWholeScreen={isWholeScreen}>
        <img
          src={
            "https://ggfrontendassets.azureedge.net/publicassets/adamsmatkasse/adams-logo-black.svg"
          }
          alt="Adams Matkasse"
          className="w-48 md:w-72 animate-bounce"
        />

        {props.text && (
          <div className="px-8 mt-4 text-center">
            <span>{props.text}</span>
          </div>
        )}
      </Loader>
    </div>
  );
}

export default LoaderContainer;
