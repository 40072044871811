import { createGlobalStyle } from "styled-components";

import variables from "./constants/variables";

/* Global styles */

// boop

const GlobalStyle = createGlobalStyle`
  @media (min-width: 768px) {
    body.body--frozen {
      margin-right: 15px;
    }
  }

  /* Global prefill styling for webkit (remove yellow fill) */
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    -webkit-text-fill-color: black;
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }

  pre {
    background: #f4f4f4;
    border: 1px solid #ddd;
    border-left: 3px solid ${variables.colors.error};
    color: #666;
    page-break-inside: avoid;
    font-family: monospace;
    font-size: 15px;
    line-height: 1.6;
    margin-bottom: 1.6em;
    max-width: 100%;
    overflow: auto;
    padding: 1em 1.5em;
    display: block;
    word-wrap: break-word;
  }


  /* Global general styles */

  // /* Text selection */
  // ::selection {
  //   background: rgba(119, 152, 2, 0.4);
  // }

  /* Helpers */
  .screenReaders {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

  .blur-img {
    filter: blur(5px);
    transition: filter 400ms;
  }

  .blur-img.lazyloaded {
    filter: blur(0);
  }
`;

export default GlobalStyle;
